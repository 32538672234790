@import 'src/styles/colors';

.wrapper {
    margin: 0 10%;
    min-width: 900px;
}

.name {
    font-weight: 400;
    color: $blue;
}

.action {
    color: #0783c1;
    font-weight: 400;
}

.createNewOrganizationButton {
    width: 130px;
    float: right;
    margin-top: -6px;
    margin-bottom: 24px;
}

.createNewButtonContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.createNewButtonText {
    padding-top: 2px;
    padding-left: 8px;
}

.download {
    display: flex;
    justify-content: flex-end;
}

.downloadButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #0783c1;
    font-weight: 400;
}

.downloadIcon {
    margin-right: 8px;
}

.rangePicker {
    width: 308px;
}
