.formWrapper {
    width: 50vw;
}

.titleDivider {
    padding: 18px 0 0 0 !important;
}

.formItemWrapper {
    height: 56px;
    margin-left: 6.6%;
}

.divider {
    padding: 0;
    margin: 0 0 12px 0;
}
