.header {
    display: flex;
    height: 64px;
    filter: drop-shadow(0 0 2px rgba(58, 84, 108, 0.1));
    min-width: 900px;
}

.pageMask {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #162c4b60;
}
