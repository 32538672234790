.row {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}

.resultTimeColumn {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;
}

.text {
    font-weight: 400;
}