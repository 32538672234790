.menu {
    padding: 0 10.25% !important;
    align-items: flex-end !important;
    min-width: 1080px;
}

.menuMain {
    @extend .menu;
    height: 56px !important;
    min-width: 1080px;
}

.menuActiveArchived {
    @extend .menu;
    height: 62px !important;
    min-width: 1080px;
    background-color: rgb(249, 251, 252) !important;
}
