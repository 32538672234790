@import 'src/styles/colors';

.inputLabel {
    display: flex;
    width: 96px;
    padding-top: 10px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding-top: 16px;
}

.parameter {
    display: flex;
    justify-content: center;
    height: 24px;
}

.emptyParameter {
    width: 108px;
}

.parameterValue {
    display: flex;
}

.arrow {
    width: 18px;
    display: flex;
    align-items: flex-end;
}

.unit {
    width: 30%;
    text-align: right;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    padding-top: 10px;
}

.noUnit {
    width: 30%;
}
