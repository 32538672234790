.wrapper {
    margin: 0 10%;
    min-width: 900px;
}

.input {
    margin-bottom: 24px;
}

.searchIcon {
    display: flex;
    align-items: center;
}
