@import 'src/styles/colors';

.labeling {
    table {
        margin-bottom: 24px;
        width: 100%;
    }

}

.tableSepsis {
    table,
    th,
    td {
        border: 3px solid white;
        border-collapse: collapse;
    }

    th {
        background-color: $blue_dark;
        color: $white;
    }
}

.regularCell {
    text-align: center;
    height: auto;
    padding: 6px 0px;
}

.labColNarrow {
    min-width: 86px;
    @extend .regularCell;
    background-color: $blue_light;
}

.labColRegular {
    width: 50%;
    min-width: 185px;
    @extend .regularCell;
    padding: 12px 8px;
    background-color: $bg_light;
}

.tableHeader {
    background-color: $blue_light;
}

.illustration {
    width: 64px;
}
