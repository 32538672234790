@import 'src/styles/colors';

.ai {
    table {
        margin-bottom: 24px;
        width: 100%;
    }

    table,
    th,
    td {
        border: 3px solid white;
        border-collapse: collapse;
    }
}

.tableHeader {
    background-color: $bg_blue_dark;
    color: white;
}

.regularCell {
    text-align: center;
    height: auto;
    padding: 6px 0px;
}

.startCol {
    @extend .regularCell;
    background-color: $blue_dark;
    color: white;
}

.blueCol {
    @extend .regularCell;
    background-color: $blue_dark;
    color: white;
}

.greyCol {
    @extend .regularCell;
    background-color: $grey_semi_light;
    width: 50%;
}

.regularCol {
    @extend .regularCell;
    background-color: $bg_light;
}

.twoColContainer {
    display: flex;
    margin-top: 12px;
}

.col {
    width: 50%;
}
