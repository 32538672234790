.wrapper {
    .cursorContainer {
        display: flex;
        width: 1032px;
        height: 10px;

        .cursor {
            display: flex;
            z-index: 1;
            align-items: center;
            justify-content: center;

            .riskCount {
                display: flex;
                align-items: flex-end;
                height: 1px;
                line-height: 0px;
                z-index: 2;
                position: absolute;
                font-weight: 700;
                color: #ffffff;
                font-size: 14px;
            }
        }
    }
}

.scaleWrapper {
    height: 59px;
}

.boundaryValues {
    display: flex;
}
