.container {
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 0 20px;
}

.sourceObservationContainer {
    @extend .container;
    background-color: #eff5f9;
}

.normalizationContainer {
    @extend .container;
    background-color: #ffffff;
}

.resultedObservationContainer {
    @extend .container;
    background-color: #eff5f9;
}
