.main {
    width: 1080px;
    background-color: #ffffff;
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 64px;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.15));
    border-radius: 4px;

    .scoreScale {
        z-index: 2;
        height: 102px;
        background-color: #f8f9fb;
        display: flex;
        padding-top: 20px;
        padding-left: 24px;
    }
}

.noResultWrapper {
    margin: 0 24px 16px 24px;
}

.stripe {
    width: 1080px;
    height: 9px;
}
