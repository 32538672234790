.container {
    position: absolute;
    top: 100px;
    right: 0;
    text-align: right;
    height: 48px;
    border-radius: 15px 0 0 15px;
    background-color: #ffd8bf;
}

.clinicalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 15px 0 0 15px;
}

.sepsis {
    color: #748c97;
    margin-left: 5px;
    margin-right: 5px;
}

.howDoesItWork {
    cursor: pointer;
    line-height: 18px;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration-line: underline;
}

.modeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50px;

}

.modeText {
    font-size: 13px;
    font-weight: 500;
    margin-left: 10px;
    margin-right: 8px;
    letter-spacing: 0.1em;
}

