.headerContainer {
    display: flex;
    width: 100vw;
    height: 64px;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    filter: drop-shadow(0 0 2px rgba(58, 84, 108, 0.1));
}

.buttonsContainer {
    display: flex;
}

.backArrowIcon {
    cursor: pointer;
    margin-left: 25.5px;
    margin-right: 17.5px;
}

.title {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 500;
}

.saveIcon {
    padding-top: 3px;
    padding-right: 10px;
}
