.wrapper {
    display: block;
    position: relative;
    top: -50px;
    right: 386px;
    min-width: 369px;
    z-index: 10;
    background-color: white;
    border: 1px solid rgba(116, 140, 151, 0.3);
    box-shadow: 0px 2px 0px 0px rgba(19, 82, 114, 0.2);
    padding: 12px 16px;
    border-radius: 4px;

    h2 {
        font-size: 14px;
        font-weight: bold;
    }

    p {
        font-size: 12px;
        margin: 0;
        padding: 0;
    }
}
