.wrapper {
    font-weight: 700;
    height: 190px;
    width: 144px;
    background: #748c97;
    border-radius: 15px;

    .percentValue {
        padding: 18.5px 0px 0px 18.5px;
        display: flex;
        color: #ffffff;
        text-shadow: 1px 1px 1px rgba(30, 33, 38, 0.35);

        .value {
            font-size: 74px;
            font-weight: 500;
            line-height: 80px;
            letter-spacing: -0.02em;
        }

        .value100 {
            margin-left: -5px;
            font-size: 58px;
            font-weight: 500;
            line-height: 80px;
            letter-spacing: -0.02em;
        }

        .percent {
            font-weight: 700;
            margin-top: -3px;
            padding-left: 2px;
            font-size: 36px;
            visibility: hidden;
        }

        .percent100 {
            font-weight: 700;
            padding-top: 6px;
            padding-left: 2px;
            font-size: 30px;
            visibility: hidden;
        }

        .waiting {
            font-size: 74px;
            font-weight: 700;
            line-height: 80px;
            letter-spacing: -0.02em;
            text-align: center;
            margin-right: 18.5px;
            width: 100%;
        }

        .noResult {
            font-size: 24px;
            font-weight: 700;
            line-height: 80px;
            letter-spacing: -0.02em;
            text-align: center;
            margin-right: 18.5px;
            width: 100%;
        }
    }

    .separator {
        margin: 20px 12px 11px 12px;
        border: 0px solid rgba(255, 255, 255, 0.2);
        border-top-width: 1px;
    }

    .description {
        color: #ffffff;
        text-shadow: 1px 1px 1px rgba(30, 33, 38, 0.35);
        margin-left: 12px;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.02em;
    }
}
