@import 'src/styles/colors';

.container {
    display: flex;
    flex-direction: column;
    padding: 48px;
    min-height: 470px;
    margin: 0 10% 24px 10%;
    background-color: #ffffff;
    border: 1px solid #d4e0e8;
    box-sizing: border-box;
    border-radius: 2px;
    min-width: 900px;
}

.searchIcon {
    display: flex;
    align-items: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.generateReportButton {
    width: 140px;
    float: right;
    margin-right: 10%;
    margin-bottom: 24px;
}