@import '~antd/dist/antd.css';
@import 'colors';

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(../fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: local('Roboto'), url(../fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: local('Roboto'), url(../fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #eff5f9;

    font-family: 'Roboto', sans-serif;
    font-variant: inherit;

    .ant-tooltip-inner {
        background-color: #1e2226;
        border-radius: 4px;
    }

    .ant-tooltip-arrow-content {
        background-color: #1e2226;
    }

    .ant-table table {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        color: #0783c1;
    }

    .ant-btn-primary {
        background: #0783c1; // TODO Change hover color
    }

    .ant-page-header-heading-title {
        font-weight: 500;
        padding-top: 2px;
    }
}
