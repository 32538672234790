.saveContainer {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    padding-left: 44px;
    padding-bottom: 12px;
}

.endDivider {
    padding-left: 44px;
    margin-top: 24px;
}

.saveIcon {
    width: 54px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: -2px;
}

.saveText {
    padding-top: 2.5px;
}
