.header {
    font-weight: 700;
    font-size: 24px;
    color: #1e2226;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
}

.headerWaiting {
    @extend .header;
    margin-top: 50px;
}

.headerFailure {
    @extend .header;
    margin-top: 30px;
}

.notResulted {
    font-weight: bold;
    font-size: 16px;
}
