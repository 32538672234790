.value {
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 169px;
    white-space: nowrap;
}

.assumedText {
    padding-right: 6px;
}

.help {
    display: flex;
    width: 15px;
    cursor: pointer;
    align-items: center;
    height: 16px;
    padding-left: 8px;
    padding-top: 6px;
    white-space: normal;
}
