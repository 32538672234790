// basic
$dark: #1E2226;
$white: #ffffff;

$blue: #0783C1;
$blue_dark: #006CA1;
$blue_light: rgba(7, 131, 193, 0.2);

$grey: #748C97;
$grey_semi_light: rgba(116, 140, 151, 0.3);
$grey_light: rgba(116, 140, 151, 0.15);
$grey_background: rgba(116, 140, 151, 0.95);

// ranges
$green: #02C165;
$orange: #FF7802;
$red: #FF3F3D;
$crimson: #C2160C;

// background
$bg_low: #CCF0DF;
$bg_medium: #FFE8CD;
$bg_high: #FFE1E0;
$bg_dark: #EFF5F9;
$bg_light: #F8F9FB;

$bg_blue_dark: #1A3D6E;

// overlay
$overlay: #162C4B;

// app theme
$primary: $blue;

// elevations
.buttonShadow {
  box-shadow: 0px 2px 0px rgba(19, 82, 114, 0.2);
}

.contentShadow {
  box-shadow: 0px 0px 14px #D6DFE6;
}
