.helpCenterWrapper {
    display: flex;
    padding-top: 48px;
    justify-content: center;
    position: absolute;
    z-index: 500;
    width: 100%;
}

.helpCenterContainer {
    display: flex;
    flex-direction: column;
    z-index: 501;
    width: 750px;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 24px;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.title {
    font-size: 24px;
    font-weight: 700;
}

.close {
    height: 32px;
    width: 32px;
    margin: -4px;
    cursor: pointer;
}
