.wrapper {
    color: rgba(30, 34, 38, 1);
    margin-right: 12px;
    width: 124px;
}

.timeType {
    font-weight: 700;
    font-size: 14px;
}

.time {
    font-size: 14px;
    white-space: pre;
}
