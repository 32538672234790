.header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .title {
        font-weight: 500;
        font-size: 30px;
    }

    .date {
        font-weight: 500;
        font-size: 24px;
    }
}

.organizationName {
    font-size: 14px;
}

.periods {
    display: flex;
    align-items: center;
    height: 54px;
    background-color: #f9fbfc;
    margin-top: 20px;
    margin-bottom: 16px;

    .periodTitle {
        margin-left: 16px;
        margin-right: 8px;
        color: rgba(0, 0, 0, 0.45);
    }
}
