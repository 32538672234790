.wrapper {
    color: #ffffff;
    font-size: 14px;
    border-radius: 2px;
    height: 22px;
    width: 32px;
    text-align: center;
    padding-top: 1px;
}

.veryHigh {
    @extend .wrapper;
    background-color: #c2160c;
}

.high {
    @extend .wrapper;
    background-color: #ff4545;
}

.medium {
    @extend .wrapper;
    background-color: #ff8a01;
}

.low {
    @extend .wrapper;
    background-color: #0ccf83;
}

.resultPending {
    @extend .wrapper;
    background-color: #bfbfbf;
}

.noResult {
    @extend .wrapper;
    background-color: #1f1f1f;
}
