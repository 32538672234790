.wrapper {
    display: flex;
    width: 100vw;
    height: calc(100vh - 64px);
}

.header {
    display: flex;
    margin-left: 20px;
    padding-top: 20px;
    cursor: pointer;

    h2 {
        margin-left: 8px;
    }
}
