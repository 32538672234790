@import 'src/styles/colors';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .separator {
        width: calc(100% - 48px);
        border-bottom: 1px solid rgba(116, 140, 151, 0.15);
    }
}

.bottomStripe {
    width: 1080px;
    height: 9px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.bottomStripeGrey {
    @extend .bottomStripe;
    background-color: $bg_light;
}
