.modeWrapper {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    .nonClinicalContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        border-radius: 15px 0 0 15px;
        background-color: #ffd8bf;
    }

    .clinicalContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        border-radius: 15px 0 0 15px;
        background-color: #d9f7be;
    }
}

.modeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background-color: red;
}

.modeText {
    font-size: 13px;
    font-weight: 500;
    margin-left: 10px;
    margin-right: 8px;
    letter-spacing: 0.1em;
}
