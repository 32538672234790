.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 470px;
    margin: 0 10%;
    background-color: #ffffff;
    border: 1px solid #d4e0e8;
    box-sizing: border-box;
    border-radius: 2px;
    min-width: 900px;
}

.wrapperNewOrganization {
    @extend .wrapper;
    width: 76.5%;
    margin: 25px 0 0 0;
    min-width: 900px;
}

.header {
    background-color: #ffffff !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    height: 72px !important;
    z-index: 10 !important;
    padding: 0 12.5% !important;
}

.formContainer {
    width: 600px;
}

.dividerName {
    padding-left: 8px;
}

.nameInput {
    height: 32px;
}

.dividerMode {
    padding-left: 8px;
    margin-top: 45px;
}

.dataDrifted {
    padding-left: 10px;
}

.divider {
    padding-left: 47px;
    margin-top: 40px;
}

.emailInput {
    height: 32px;
}

.saveContainer {
    padding-left: 47px;
}

.mode {
    padding-left: 50px;
}

.saveButton {
    display: flex;
}

.saveIcon {
    margin-top: 2px;
    margin-right: 10px;
}

.dividerDataDrift {
    padding-left: 15px;
}

.dataDriftSettings {
    margin-left: -70px;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
}
