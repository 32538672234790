.line {
    font-size: 16px;
    color: #748c97;
    visibility: hidden;

    .percent {
        font-weight: 700;
        color: #1e2226;
    }

    .diagnostic {
        color: #1e2226;
    }
}
