.parameter {
    display: flex;
    width: 200px;

    .display {
        font-size: 16px;
    }
}

.help {
    display: flex;
    width: 15px;
    cursor: pointer;
    align-items: center;
    height: 16px;
    padding-left: 8px;
    padding-top: 16px;
}
