.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 470px;
    margin: 0 10% 24px 10%;
    background-color: #ffffff;
    border: 1px solid #d4e0e8;
    box-sizing: border-box;
    border-radius: 2px;
    min-width: 900px;

    input {
        height: 24px;
    }
}
