.menu {
    padding: 0 10.25% !important;
    min-width: 1080px;
}

.header {
    display: flex;
    height: 64px;
    padding: 0 11.5% !important;
    background-color: #ffffff !important;
    min-width: 1080px !important;
}
