.wrapper {
    height: 64px !important;
    box-shadow: 0px 2px 8px #f0f1f2;
    background-color: #ffffff !important;
    padding: 0 40px !important;
    position: relative;
    z-index: 100;
    min-width: 1080px;

    .leftSide {
        float: left;
        display: flex;

        .logo {
            margin-top: 4px;
            margin-bottom: -4px;
        }

        .title {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 14px;
            color: #a6192e;
            margin: 0px 10px;
            letter-spacing: 0.04em;
        }
    }

    .rightSide {
        float: right;
        display: flex;
        align-items: center;

        .logout {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 24px;
            width: 93px;
            padding-left: 8px;

            .logoutInside {
                display: flex;
            }
        }

        .nameContainer {
            display: flex;
            align-items: center;

            .nameInitial {
                display: flex;
                width: 24px;
                height: 24px;
                background: #a6192e;
                border-radius: 30px;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                color: #ffffff;
                font-size: 16px;
                margin: 0 8px 0 28px;
            }

            .fullname {
                font-size: 13px;
                color: #1a3d6e;
            }
        }
    }
}
