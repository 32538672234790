.wrapper {
    display: flex;
    height: 238px;
    padding: 24px 0 24px 24px;

    .rightSideWrapper {
        margin-left: 24px;

        .separator {
            width: 405px;
            margin: 20.5px 0 16px 0;
            border: 0px solid #e9f1f7;
            border-top-width: 1px;
        }

        .separatorWaiting {
            @extend .separator;
            margin: 6px 0 12px 0;
        }

        .separatorFailure {
            @extend .separator;
            margin: 12px 0 12px 0;
        }

        .timeWrapper {
            display: flex;
        }
    }
}
