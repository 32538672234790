@import 'src/styles/colors';

.tableHeader {
    display: flex;
    position: sticky;
    top: -1px;
    z-index: 1;
    background-color: #ffffff;
    width: calc(100% - 48px);
    height: 46px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #bdcad0;
    margin-top: 8px;
    font-weight: 700;
    font-size: 14px;

    .scoreImpact {
        flex-grow: 1;
    }

    .parameter {
        width: 200px;
    }

    .value {
        width: 169px;
    }

    .collectionTime {
        width: 128px;
    }
}

.tableHeaderGrey {
    @extend .tableHeader;
    background-color: $bg_light;
}
