@import 'src/styles/colors';

.table {
    width: 640px;
    border-spacing: 4px 0px;
    border-collapse: separate;
    line-height: 18px;
}

.separator {
    position: absolute;
    width: 632.5px;
    border-bottom: 0.5px solid rgba(116, 140, 151, 0.3);
    padding-right: 24px;
}

.regularCell {
    text-align: center;
    height: auto;
    width: 98px;
    padding: 13px 0px;
}

.backgroundColorNeutral {
}

.backgroundColorLow {
    background-color: #ccf0df;
}

.backgroundColorMedium {
    background-color: #ffe8cd;
}

.backgroundColorHigh {
    background-color: #ffe1e0;
}

.backgroundColorVeryHigh {
    background-color: #ffd0cd;
}

.neutral {
    @extend .regularCell;
    @extend .backgroundColorNeutral;
}

.low {
    @extend .regularCell;
    @extend .backgroundColorLow;
}

.medium {
    @extend .regularCell;
    @extend .backgroundColorMedium;
}

.high {
    @extend .regularCell;
    @extend .backgroundColorHigh;
}

.veryHigh {
    @extend .regularCell;
    @extend .backgroundColorVeryHigh;
}

.lastCell {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.lastCellLow {
    @extend .low;
    @extend .lastCell;
}

.lastCellMedium {
    @extend .medium;
    @extend .lastCell;
}

.lastCellHigh {
    @extend .high;
    @extend .lastCell;
}

.lastCellVeryHigh {
    @extend .veryHigh;
    @extend .lastCell;
}

.categoryTitle {
    text-align: left;
    height: 42px;
    font-weight: 400;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.titleCell {
    text-align: center;
    height: 42px;
}

.categoryTitleNeutral {
    @extend .categoryTitle;
    @extend .titleCell;
    font-weight: 700;
}

.categoryTitleLow {
    @extend .categoryTitle;
    @extend .titleCell;
    @extend .backgroundColorLow;
    font-weight: 700;
}

.categoryTitleMedium {
    @extend .categoryTitle;
    @extend .titleCell;
    @extend .backgroundColorMedium;
    font-weight: 700;
}

.categoryTitleHigh {
    @extend .categoryTitle;
    @extend .titleCell;
    @extend .backgroundColorHigh;
    font-weight: 700;
}

.categoryTitleVeryHigh {
    @extend .categoryTitle;
    @extend .titleCell;
    @extend .backgroundColorVeryHigh;
    font-weight: 700;
}

.grayText {
    font-size: 12px;
    color: #748c97;
    padding-bottom: 4px;
}

.lastLine {
    border-bottom: 0px;
}

.riskCategoryTitle {
    padding: 12px 12px 12px 0;
}

.subHeader {
    margin-top: 36px;
}

.thresholdCell {
    padding: 12px 12px 12px 0;
}

.descriptionCell {
    padding: 12px 12px 12px 0;
    text-align: center;
}

.tableInterpretation {
    table,
    th,
    td {
        border: 3px solid white;
        border-collapse: collapse;
    }

    th {
        background-color: $bg_blue_dark;
        color: $white;
    }
    width: 100%;
}

.tableHeader {
    background-color: $blue_light;
}

.tableTitle {
    margin-top: 20px;
}

.regularCell {
    text-align: center;
    height: auto;
    padding: 6px 4px;
    color: $bg_blue_dark;
}

.labColWide {
    @extend .regularCell;
    min-width: 125px;
    background-color: $bg_light;
}

.labColFirst {
    @extend .labColWide;
    font-weight: 700;
    background-color: $grey_semi_light;
}

.labColRegular {
    @extend .regularCell;
    background-color: $bg_light;
    white-space: nowrap;
}

.labCaption {
    caption-side: top;
    text-align: left;
    font-weight: bold;
}
