@import 'src/styles/colors';

.regularImage {
    width: 100%;
}

.bigImagePreview {
    width: 100%;
    cursor: pointer;
}

.bigImageExpanded {
    width: 100%;
    padding: 10px;
}

.expandedModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background-color: #ffffff;
    z-index: 2;
}

.expandedBackground {
    background: $grey_background;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1;
}

.closeCircleIcon {
    position: fixed;
    z-index: 1000;
    right: 12px;
    top: 12px;
}
