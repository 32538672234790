.dotPulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #748c97;
    color: #748c97;
    box-shadow: 9999px 0 0 -5px #748c97;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
    margin-left: 30px;
}

.dotPulse::before,
.dotPulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #748c97;
    color: #748c97;
}

.dotPulse::before {
    box-shadow: 9984px 0 0 -5px #748c97;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dotPulse::after {
    box-shadow: 10014px 0 0 -5px #748c97;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #748c97;
    }
    30% {
        box-shadow: 9984px 0 0 2px #748c97;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #748c97;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #748c97;
    }
    30% {
        box-shadow: 9999px 0 0 2px #748c97;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #748c97;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #748c97;
    }
    30% {
        box-shadow: 10014px 0 0 2px #748c97;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #748c97;
    }
}
