.wrapper {
    width: 100%;
}

.measurementsContainer {
    display: flex;
    background: #ffe8cd;
    box-shadow: inset 0px 2px 0px #ff3f3d;
    border-radius: 0px 0px 4px 4px;
}

.awaitingResultsContainer {
    display: flex;
    background: #f8f9fb;
    border-radius: 4px;
    margin-top: 8px;
}

.waitTimeHasExpiredContainer {
    display: flex;
    background: #eff5f9;
    box-shadow: inset 0px 2px 0px #1e2126;
    border-radius: 0px 0px 4px 4px;
}

.title {
    font-size: 14px;
    font-weight: bold;
    width: 168px;
    letter-spacing: 0.02em;
    padding: 16px 0px 16px 24px;
}

.items {
    margin: 10px 24px 10px 24px;
    width: 100%;
}

.item {
    display: flex;
    flex-direction: row;
    height: 34px;
    align-items: center;
}

.display {
    min-width: 192px;
    font-size: 16px;
}

.message {
    display: flex;
    min-width: 262px;
    align-items: center;
    padding-top: 3px;

    span {
        margin-right: 6px;
    }

    span.icon {
        padding-top: 1px;
    }
}

.helpText {
    min-width: 250px;
    padding-top: 3px;
}
