.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 470px;
    margin: 0 10% 24px 10%;
    padding: 12px 0 12px 0;
    background-color: #ffffff;
    border: 1px solid #d4e0e8;
    box-sizing: border-box;
    border-radius: 2px;
    min-width: 900px;

    div {
        box-sizing: border-box;
        margin: 0 !important;

        .fieldContainer {
            margin-bottom: 24px !important;
        }
    }

    input {
        height: 32px;
    }
}

.fieldContainer {
    display: flex;
    width: 72vw;
    height: 32px;
    padding-right: 10vw;
}

.removeButton {
    background-color: #ca1c36 !important;
    height: 32px !important;
    width: 32px !important;
    padding: 7px !important;
    margin: 0 4px 0 12px !important;
    border-radius: 2px !important;
}

.saveEditButton {
    background-color: #0783c1 !important;
    height: 32px !important;
    width: 32px !important;
    padding: 7px !important;
    margin: 0 4px !important;
    border-radius: 2px !important;
}

.saveEditButtonDisabled {
    @extend .saveEditButton;
    background-color: #f5f5f5 !important;
}

.createButton {
    @extend .saveEditButton;
    margin: 0px !important;
    margin-bottom: 12px !important;
}
