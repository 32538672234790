.tableItem:hover {
    background-color: #f8f9fb;
}

.tableItem {
    display: flex;
    width: calc(100% - 48px);
    height: 36px;
    align-items: center;
    font-size: 15px;

    .scoreImpact {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
    }
}
