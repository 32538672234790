@import 'src/styles/colors';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .separator {
        width: calc(100% - 48px);
        border-bottom: 1px solid rgba(116, 140, 151, 0.15);
    }
}

.containerGrey {
    @extend .container;
    background-color: $bg_light;
    margin-top: 8px;
}

.separator {
    width: calc(100% - 48px);
    border-bottom: 1px solid #e9f1f7;
}
