.download {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #ffffff;
    font-weight: 400;
}

.downloadIcon {
    margin-right: 8px;
}

.rangePicker {
    width: 308px;
}

.generateReportButton {
    width: 140px;
    float: right;
    margin-right: 10%;
    margin-bottom: 24px;
}